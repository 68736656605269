import { Box } from "@mui/material";
import React from "react";
import sanitizeHtml from "sanitize-html";
import { IAgentMsg } from "../../../models/interfaces/workbench/agent/IAgentMsg";
import { IChatParticipant } from "../../../models/interfaces/workbench/chat/IChatParticipant";
import { IMessage } from "../../../models/interfaces/workbench/chat/IMessage";
import { MessageTypeEnum } from "../../../models/interfaces/workbench/chat/MessageTypeEnum";
import { chatMessageBodyStyle, chatMessageStyle } from "./ChatMessagesStyle";

interface IChatMessageProp {
    chatParticipantProfiles: Map<string, IChatParticipant>;
    message: IMessage;
    index: number;
}

const stripNamePattern = (content: string): string => {
    // Remove pattern like "[name] to [name]:" or "[name]:" from the start of content
    return content.replace(/^(?:[^\n]*?to[^\n]*?:|[^\n]*?:)\s*/i, "").trim();
};

export const ChatMessage: React.FC<IChatMessageProp> = ({
    chatParticipantProfiles,
    message,
    index,
}: IChatMessageProp) => {
    let content = message?.llmMessage?.content;
    if (message?.messageType === MessageTypeEnum.AgentAction) {
        content = (JSON.parse(message.llmMessage.content) as IAgentMsg).message;
    }

    // Strip the name pattern from content
    content = content ? stripNamePattern(content) : "";

    if (message?.llmMessage?.role === "system") {
        return (
            <Box key={index} sx={chatMessageStyle}>
                <Box sx={{ ...chatMessageBodyStyle, display: "flex", alignItems: "flex-start", gap: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexShrink: 0 }}>
                        <Box component="span" sx={{ fontWeight: "bold", display: "inline" }}>
                            System
                        </Box>
                        <Box component="span" sx={{ display: "inline" }}>
                            :
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        {sanitizeHtml(message?.llmMessage?.content, {
                            allowedTags: ["br", "a"],
                            allowedAttributes: { a: ["href"] },
                        })}
                    </Box>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box key={index} sx={chatMessageStyle}>
                <Box sx={{ ...chatMessageBodyStyle, display: "flex", alignItems: "flex-start", gap: 1 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1, flexShrink: 0 }}>
                        <Box component="span" sx={{ fontWeight: "bold", display: "inline" }}>
                            {`${chatParticipantProfiles.get(message.fromUserId)?.participantName}`}
                        </Box>
                        <Box component="span" sx={{ display: "inline" }}>
                            to
                        </Box>
                        <Box component="span" sx={{ fontWeight: "bold", display: "inline" }}>
                            {chatParticipantProfiles.has(message.toUserIds[0])
                                ? `${chatParticipantProfiles.get(message.toUserIds[0])?.participantName}`
                                : ""}
                        </Box>
                        <Box component="span" sx={{ display: "inline" }}>
                            :
                        </Box>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        {sanitizeHtml(content, {
                            allowedTags: ["br", "a"],
                            allowedAttributes: { a: ["href"] },
                        })}
                    </Box>
                </Box>
            </Box>
        );
    }
};
