import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, Grid } from "@mui/material";
import React, { useState } from "react";
import { IPrompt } from "../../../models/interfaces/prompt/IPrompt";
import { ISolution } from "../../../models/interfaces/solution/ISolution";
import { IAgent } from "../../../models/interfaces/workbench/agent/IAgent";
import { LoadEntityTypeEnum } from "../../../types/entity/LoadEntityEnum";
import GetRequestHeader from "../../../utils/auth_utils";
import DropMenu from "./DropMenu";
import style from "./PromptActionButtonsStyle.module.css";
import SavePromptModal from "./prompt_modal/SavePromptModal";

interface responseData {
    metrics: Record<string, string>;
    output: string;
}

interface IPromptActionButtonsProps {
    startNewConversation: (agentList: { [agentId: string]: number }) => Promise<void>;
    userId: string;
    userName: string;
    currentPrompt: IPrompt;
    setCurrentPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
    currentAgent: IAgent | undefined;
    setCurrentAgent: React.Dispatch<React.SetStateAction<IAgent | undefined>>;
    selectedAgents: {
        [key: string]: IAgent;
    };
    setSelectedAgents: React.Dispatch<React.SetStateAction<{ [key: string]: IAgent }>>;
    isAgentPrompt: boolean;
    setIsAgentPrompt: React.Dispatch<React.SetStateAction<boolean>>;
    entityType: LoadEntityTypeEnum;
    currentSolution: ISolution | undefined;
    setCurrentSolution: React.Dispatch<React.SetStateAction<ISolution | undefined>>;
}

const PromptActionButtons: React.FC<IPromptActionButtonsProps> = (props: IPromptActionButtonsProps) => {
    // for prompt saving modal
    const [isOpen, setOpen] = React.useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleStartPromptTesting = async (): Promise<void> => {
        try {
            const agentList = Object.values(props.selectedAgents).reduce((acc, agent) => {
                acc[agent.agentId] = agent.versionId;
                return acc;
            }, {} as { [agentId: string]: number });
            await props.startNewConversation(agentList);
            console.log("Output sent successfully");
        } catch (error) {
            console.error("An error occurred while sending the output:", error);
        }
    };

    const [, setResponseText] = useState<responseData>({
        metrics: {},
        output: "",
    });

    const handleDeploy = async (buildText: string): Promise<void> => {
        alert("Deploying to the service");

        try {
            const response = await fetch(
                "https://4c2633a9-e8d3-419a-9e8d-25a356778093.mock.pstmn.io",
                GetRequestHeader({
                    method: "POST",
                    body: JSON.stringify({ input: buildText }),
                    authToken: await getAccessTokenSilently(),
                })
            );

            if (response.ok) {
                const responseData = await response.json();
                setResponseText(responseData);
            } else {
                console.error("API request failed");
            }
        } catch (error) {
            console.error("An error occurs", error);
        }
    };

    const handleOpen = (): void => {
        setOpen(true);
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Box display="none" justifyContent="center" alignItems="center">
                    <Button
                        className={style.buildButton}
                        variant="contained"
                        color="warning"
                        disabled={true}
                        onClick={async () => {
                            await handleDeploy("buildText");
                        }}
                    >
                        Deploy to {props.entityType} Service
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box display="flex">
                    <DropMenu />
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button variant="contained" color="secondary" className={style.buildButton} onClick={handleOpen}>
                        View & Save Details
                    </Button>
                    <SavePromptModal
                        props={{
                            userId: props.userId,
                            userName: props.userName,
                            isSavePromptModalOpen: isOpen,
                            setIsSavePromptModalOpen: setOpen,
                            prompt: props.currentPrompt,
                            setPrompt: props.setCurrentPrompt,
                            agent: props.currentAgent,
                            setAgent: props.setCurrentAgent,
                            selectedAgents: props.selectedAgents,
                            setSelectedAgents: props.setSelectedAgents,
                            isAgentPrompt: props.isAgentPrompt,
                            setIsAgentPrompt: props.setIsAgentPrompt,
                            entityType: props.entityType,
                            solution: props.currentSolution,
                            setSolution: props.setCurrentSolution,
                        }}
                    />
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Button
                        variant="contained"
                        color="secondary"
                        className={style.buildButton}
                        onClick={handleStartPromptTesting}
                    >
                        Chat with {props.entityType}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};
export default PromptActionButtons;
