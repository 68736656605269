import CloseIcon from "@mui/icons-material/Close";
import { Alert, Box, IconButton, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { IPrompt } from "../../../../models/interfaces/prompt/IPrompt";
import { ISolution } from "../../../../models/interfaces/solution/ISolution";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";
import { LoadEntityTypeEnum } from "../../../../types/entity/LoadEntityEnum";
import { LoadEntityOptionBaseType } from "../../../../types/entity/LoadEntityOptionBaseType";
import GetRequestHeader from "../../../../utils/auth_utils";
import { StringUtils } from "../../../../utils/string_utils";
import LoadButtonGroup from "../content/LoadButtonGroup";
import LoadOptionSelect from "../content/LoadOptionSelect";

interface ILoadEntityFrameworkProps<T> {
    userId: string;
    isLoadModalOpen: boolean;
    setIsLoadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    entity: LoadEntityOptionBaseType<T>;
    selectedTabKey: string;
    setEntityType: React.Dispatch<React.SetStateAction<LoadEntityTypeEnum>>;
}

const GetOptions = (authToken?: string) => GetRequestHeader({ method: "GET", authToken: authToken });

function LoadEntityFramework<T>(props: ILoadEntityFrameworkProps<T>) {
    const { getAccessTokenSilently } = useAuth0();
    const [snackbar, setSnackbar] = React.useState<{ open: boolean; message: string; severity: "error" | "success" }>({
        open: false,
        message: "",
        severity: "error",
    });

    const handleCloseSnackbar = () => {
        setSnackbar((prev) => ({ ...prev, open: false }));
    };

    const showError = (message: string) => {
        setSnackbar({
            open: true,
            message,
            severity: "error",
        });
    };

    const fetchAvailableOptions = async () => {
        try {
            if (props.isLoadModalOpen === false) return;
            props.entity.setAvailableEntityRequest((prevState) => {
                return {
                    ...prevState,
                    loading: true,
                };
            });
            const authToken = await getAccessTokenSilently();
            if (authToken === undefined || authToken === null || authToken === "")
                throw new Error("Auth token is invalid");
            const headers = GetOptions(authToken);
            const resp = await fetch(props.entity.listEntityUrl, headers);
            if (!resp.ok) throw new Error("API request failed: " + resp.statusText);
            const data = await resp.json();
            props.entity.setAvailableEntityRequest((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                    availableOptions: (data as T[])?.sort((a: T, b: T) => {
                        switch (props.entity.entityType) {
                            case LoadEntityTypeEnum.Solution: {
                                return (a as ISolution).solutionName.localeCompare((b as ISolution).solutionName);
                            }
                            case LoadEntityTypeEnum.Prompt: {
                                return (a as IPrompt).promptName.localeCompare((b as IPrompt).promptName);
                            }
                            case LoadEntityTypeEnum.Agent: {
                                return (a as IAgent).agentName.localeCompare((b as IAgent).agentName);
                            }
                            default: {
                                return 0;
                            }
                        }
                    }),
                    error: null as unknown as Error,
                };
            });
        } catch (error: Error | any) {
            console.log(error);
            const errorMessage = error?.message || "An error occurred while fetching options";
            showError(errorMessage);
            props.entity.setAvailableEntityRequest((prevState) => {
                return {
                    ...prevState,
                    error: error,
                };
            });
        } finally {
            props.entity.setAvailableEntityRequest((prevState) => {
                return {
                    ...prevState,
                    loading: false,
                };
            });
        }
    };

    const handleDeleteRequest = async () => {
        try {
            const authToken = await getAccessTokenSilently();
            if (authToken === undefined || authToken === null || authToken === "")
                throw new Error("Auth token is invalid");
            if (StringUtils.IsStringUndefinedNullOrEmpty(props.entity.selectedOptionId)) return;
            await props.entity.deleteEntityAsync(props.entity.selectedOptionId, authToken);
            props.entity.setSelectedOptionId("");
            props.setIsLoadModalOpen(false);
        } catch (error: Error | any) {
            console.error("An error occurs", error);
            const errorMessage = error?.message || "An error occurred while deleting";
            showError(errorMessage);
        }
    };

    const handleLoadRequest = async () => {
        try {
            const authToken = await getAccessTokenSilently();
            if (authToken === undefined || authToken === null || authToken === "")
                throw new Error("Auth token is invalid");
            if (StringUtils.IsStringUndefinedNullOrEmpty(props.entity.selectedOptionId)) return;
            await props.entity.loadEntityAsync(props.entity.selectedOptionId, authToken);
            props.setIsLoadModalOpen(false);
            props.setEntityType(props.entity.entityType);
        } catch (error: Error | any) {
            console.error("An error occurs", error);
            const errorMessage = error?.message || "An error occurred while loading";
            showError(errorMessage);
        }
    };

    const containsTextIgnoreCase = (text: string, searchText: string) =>
        text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

    const displayedOptions = useMemo(() => {
        if (
            props.entity.availableEntityRequest.availableOptions === null ||
            props.entity.availableEntityRequest.availableOptions === undefined
        )
            return () => [];

        switch (props.entity.entityType) {
            case LoadEntityTypeEnum.Solution: {
                return () =>
                    props.entity.availableEntityRequest.availableOptions
                        .filter((option) =>
                            containsTextIgnoreCase((option as ISolution).solutionName, props.entity.searchText)
                        )
                        .map((option) => {
                            return {
                                id: (option as ISolution).solutionId,
                                displayName: (option as ISolution).solutionName,
                            };
                        });
            }
            case LoadEntityTypeEnum.Prompt: {
                return () =>
                    props.entity.availableEntityRequest.availableOptions
                        .filter((option) =>
                            containsTextIgnoreCase((option as IPrompt).promptName, props.entity.searchText)
                        )
                        .map((option) => {
                            return {
                                id: (option as IPrompt).promptId,
                                displayName: (option as IPrompt).promptName,
                            };
                        });
            }
            case LoadEntityTypeEnum.Agent: {
                return () =>
                    props.entity.availableEntityRequest.availableOptions
                        .filter((option) =>
                            containsTextIgnoreCase((option as IAgent).agentName, props.entity.searchText)
                        )
                        .map((option) => {
                            return {
                                id: (option as IAgent).agentId,
                                displayName: (option as IAgent).agentName,
                            };
                        });
            }
            default: {
                return () => [];
            }
        }
    }, [props.entity.availableEntityRequest.availableOptions, props.entity.entityType, props.entity.searchText]);

    const getOptionName = () => {
        if (
            StringUtils.IsStringUndefinedNullOrEmpty(props.entity.selectedOptionId) ||
            props.entity.availableEntityRequest.availableOptions === null ||
            props.entity.availableEntityRequest.availableOptions === undefined
        )
            return "";

        const index = props.entity.availableEntityRequest.availableOptions.findIndex((opt: T) => {
            switch (props.entity.entityType) {
                case LoadEntityTypeEnum.Solution: {
                    return (opt as ISolution).solutionId === props.entity.selectedOptionId;
                }
                case LoadEntityTypeEnum.Prompt: {
                    return (opt as IPrompt).promptId === props.entity.selectedOptionId;
                }
                case LoadEntityTypeEnum.Agent: {
                    return (opt as IAgent).agentId === props.entity.selectedOptionId;
                }
                default: {
                    return false;
                }
            }
        });

        if (index === null || index === undefined || index < 0)
            throw new Error(`Option ${props.entity.selectedOptionId} is invalid`);

        switch (props.entity.entityType) {
            case LoadEntityTypeEnum.Solution: {
                return (props.entity.availableEntityRequest.availableOptions[index] as ISolution).solutionName;
            }
            case LoadEntityTypeEnum.Prompt: {
                return (props.entity.availableEntityRequest.availableOptions[index] as IPrompt).promptName;
            }
            case LoadEntityTypeEnum.Agent: {
                return (props.entity.availableEntityRequest.availableOptions[index] as IAgent).agentName;
            }
            default: {
                return "";
            }
        }
    };

    useEffect(() => {
        if (!props.isLoadModalOpen) return;
        if (props.selectedTabKey !== props.entity.entityType.toString()) return;
        fetchAvailableOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadModalOpen, props.selectedTabKey, props.entity.entityType]);

    return (
        <Box>
            <IconButton
                style={{ position: "absolute", right: 8, top: 8 }}
                onClick={() => props.setIsLoadModalOpen(false)}
            >
                <CloseIcon />
            </IconButton>
            <Box className="modal-content">
                <Typography className="MuiTypography-root MuiTypography-body1 css-rvlace-MuiTypography-root">
                    Select {props.entity.entityType}
                </Typography>
                <LoadOptionSelect
                    props={{
                        entity: {
                            type: props.entity.entityType,
                            selectedOptionId: props.entity.selectedOptionId,
                            setSelectedOptionId: props.entity.setSelectedOptionId,
                            setSearchText: props.entity.setSearchText,
                            getOptionName: getOptionName,
                            getDisplayedOptions: displayedOptions,
                            loading: props.entity.availableEntityRequest.loading,
                        },
                    }}
                />
                <LoadButtonGroup
                    props={{
                        handleDeleteRequest: handleDeleteRequest,
                        handleLoadRequest: handleLoadRequest,
                        setIsLoadModalOpen: props.setIsLoadModalOpen,
                        entityType: props.entity.entityType,
                    }}
                />
            </Box>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}

export default LoadEntityFramework;
