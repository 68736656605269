import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { REACT_APP_BACKEND_PROTECTED_URL } from "../../../../configs/config";
import { IPrompt } from "../../../../models/interfaces/prompt/IPrompt";
import { ISolution } from "../../../../models/interfaces/solution/ISolution";
import { IAgent } from "../../../../models/interfaces/workbench/agent/IAgent";
import { LoadEntityTypeEnum } from "../../../../types/entity/LoadEntityEnum";
import { LoadEntityOptionBaseType } from "../../../../types/entity/LoadEntityOptionBaseType";
import { GetBatchAgentDefinitionByIds } from "../../../agent/ManageAgent";
import { loadPrompt } from "../../../prompt/ManagePrompt";
import { DeleteSolutionById, GetSolutionById } from "../../../solution/ManageSolution";
import LoadEntityFramework from "../framework/LoadEntityFramework";

interface ILoadSolutionProps {
    props: {
        userId: string;
        isLoadModalOpen: boolean;
        setIsLoadModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        currentSolution: ISolution | undefined;
        setCurrentSolution: React.Dispatch<React.SetStateAction<ISolution | undefined>>;
        selectedTabKey: string;
        setEntityType: React.Dispatch<React.SetStateAction<LoadEntityTypeEnum>>;
        setSelectedAgents: React.Dispatch<React.SetStateAction<{ [agentId: string]: IAgent }>>;
        setCurrentPrompt: React.Dispatch<React.SetStateAction<IPrompt>>;
    };
}

const LoadSolution = ({ props }: ILoadSolutionProps) => {
    const { getAccessTokenSilently } = useAuth0();

    const [availableSolutionRequest, setAvailableSolutionRequest] = useState({
        loading: false,
        availableOptions: [] as ISolution[],
        error: null as unknown as Error,
    });
    const [selectedOptionId, setSelectedOptionId] = useState("");
    const [searchText, setSearchText] = useState("");

    const listSolutionUrl = `${REACT_APP_BACKEND_PROTECTED_URL}/solution/filter?ownerId=${props.userId}`;

    const deleteSolution = async (solutionId: string) => {
        const index = availableSolutionRequest.availableOptions.findIndex(
            (solution) => solution.solutionId === selectedOptionId
        );
        if (index === null || index === undefined || index < 0) throw new Error(`Index ${index} is invalid`);
        const authToken = await getAccessTokenSilently();
        if (authToken === undefined || authToken === null || authToken === "") throw new Error("Auth token is invalid");
        const deletedObj: ISolution = await DeleteSolutionById(solutionId, authToken);
        props.setCurrentSolution({} as ISolution);
        return deletedObj;
    };

    const loadSolution = async (solutionId: string): Promise<ISolution> => {
        const index = availableSolutionRequest.availableOptions.findIndex(
            (solution) => solution.solutionId === solutionId
        );
        if (index === null || index === undefined || index < 0) throw new Error(`Index ${index} is invalid`);

        const authToken = await getAccessTokenSilently();
        if (authToken === undefined || authToken === null || authToken === "") throw new Error("Auth token is invalid");
        const solutionObj = await GetSolutionById(solutionId, authToken);
        const promptObj = await loadPrompt(
            Object.keys(solutionObj.solutionSystemPrompt)[0],
            Object.values(solutionObj.solutionSystemPrompt)[0],
            authToken
        );
        const agentObjs = await GetBatchAgentDefinitionByIds(solutionObj.agents, authToken);
        props.setCurrentSolution(solutionObj);
        props.setCurrentPrompt(promptObj);
        props.setSelectedAgents(agentObjs);
        setSelectedOptionId(solutionObj.solutionId);
        return solutionObj;
    };

    return (
        <LoadEntityFramework
            userId={props.userId}
            isLoadModalOpen={props.isLoadModalOpen}
            setIsLoadModalOpen={props.setIsLoadModalOpen}
            entity={
                {
                    entityType: LoadEntityTypeEnum.Solution,
                    selectedOptionId: selectedOptionId,
                    setSelectedOptionId: setSelectedOptionId,
                    searchText: searchText,
                    setSearchText: setSearchText,
                    currentEntity: props.currentSolution,
                    setCurrentEntity: props.setCurrentSolution,
                    deleteEntityAsync: deleteSolution,
                    loadEntityAsync: loadSolution,
                    listEntityUrl: listSolutionUrl,
                    availableEntityRequest: availableSolutionRequest,
                    setAvailableEntityRequest: setAvailableSolutionRequest,
                } as LoadEntityOptionBaseType<ISolution>
            }
            selectedTabKey={props.selectedTabKey}
            setEntityType={props.setEntityType}
        />
    );
};

export default LoadSolution;
